import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import "./about.scss"

const About = ({ location }) => (
  <Layout page="About" url="about">
    <SEO title="About Максим Дубровкин" location={location} />
    <div className="content page">
      <section className="about-me">
        <h1>Привет, я Максим<span role="img" aria-label="wave-emoji">👋</span></h1>
        <p>
          Я опытный Full Stack разработчик с более чем 12-летним стажем в разработке программного обеспечения. Моя экспертиза охватывает полный цикл создания веб-приложений — от проектирования архитектуры до развертывания и оптимизации.

          Я глубоко увлечен изучением передовых технологий и применением их для решения сложных задач, создавая надежные и масштабируемые решения. Мой опыт позволяет мне эффективно работать как в команде, так и над индивидуальными проектами, всегда стремясь к высокому качеству и инновационным подходам.
        </p>
        <p>Буду рад вашему обращению, если вы хотите:</p>
        <ul>
          <li>Задать вопрос, связанный с IT (например, разработка веб-приложений, базы данных, DevOps);</li>
          <li>Предложить интересную тему для блога;</li>
          <li>Поделиться проектом, в котором я могу вам помочь (например, разработка веб-сайта, оптимизация кода или консультация).</li>
        </ul>

        <p>Контакты для связи:</p>
        <ul>
          <li>telegram - @MaxDubrovkin</li>
          <li><a href="https://habr.com/ru/users/Dubrovkinmaxim/">Пишите мне на Habr.com</a></li>
          <li><a href="https://www.linkedin.com/in/maxim-dubrovkin-9036a825/">Пишите мне на LinkedIn</a></li>
        </ul>

        <p>
          Не стесняйтесь связаться со мной! 🚀
        </p>
      </section>
    </div>
  </Layout>
)

export default About;
